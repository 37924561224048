import React from 'react';
import { useLocation } from 'react-router-dom';
import TrainingPacksList from '../../components/TrainingPacksList'

export const RocketLeague = ({ match: {params: {content}} }) => {
  if (content === 'whataburger-decal') {
    return <WhataburgerDecal />;
  } else if (content === 'training-packs') {
    return <TrainingPacks />;
  } else {
    return <Home />;
  }
}

const Home = () => (
  <div className="container">
  <h2>Rocket League Things</h2>
  <h5>BakkesMod Plugins</h5>
  <h3>All of these plugins are essentially defunct and I've removed them from bakkesplugins.com.</h3>
  <ul>
    <li><a href="https://bakkesplugins.com/plugins/view/99">Team Training plugin</a> for BakkesMod / <a href="https://github.com/daftpenguin/teamtrainingplugin">GitHub</a></li>
    <ul>
      <li>Includes training pack browsing, downloading, searching, and organization that also supports single player custom training packs.</li>
      <li><a href="/rocket-league/training-packs">Web UI</a> to browse single player custom training packs uploaded via Team Training plugin</li>
      <li><a href="https://github.com/daftpenguin/trainingpacks">GitHub</a> of daily backups of training pack files on server</li>
    </ul>
    <li><a href="https://bakkesplugins.com/plugins/view/135">Hoops Kickoff Practice Plugin</a> / <a href="https://github.com/daftpenguin/HoopsKickoffPracticePlugin">GitHub</a></li>
    <ul>
      <li>Freezes ball on kickoff in freeplay until you move your car. Allows you to control your spawn location and even supports multiplayer hoops freeplay sessions via Rocket Plugin.</li>
    </ul>
    <li><a href="https://bakkesplugins.com/plugins/view/202">BakkesMod Loadout Randomizer Plugin</a> / <a href="https://github.com/daftpenguin/RandomBMPreset">GitHub</a></li>
    <ul>
      <li>Randomizes all equipped items available through BakkesMod's item mod.</li>
    </ul>
    <li><a href="https://bakkesplugins.com/plugins/view/215">Stream API Plugin</a> / <a href="https://github.com/daftpenguin/StreamAPIPlugin">GitHub</a> / <a href="/rocket-league/stream-api/setup">Setup</a></li>
    <ul>
      <li>API for creating chat commands that automatically update so viewers can retrieve your settings, equipped items, ranks, and more as you change them in game.</li>
    </ul>
  </ul>

  <h5>Custom Items</h5>
  <ul>
    <li><a href="/rocket-league/whataburger-decal">Whataburger flag, banners, and decals for Octane, Skyline, and F-150</a> (made for TriHouse)</li>
  </ul>
      
  </div>
);

const WhataburgerDecal = () => (
  <div className="container">
    <h2>Whataburger Decal</h2>
    <ol>
      <li>You must have <a href="https://bakkesmod.com/download.php">BakkesMod</a> and the <a href="https://bakkesplugins.com/plugins/view/108">AlphaConsole plugin</a> for BakkesMod installed</li>
      <li><a href="/downloads/rocket-league/custom-items/Whataburger.zip">Download the Whataburger.zip</a></li>
      <li>Extract Whataburger.zip into: %appdata%\bakkesmod\bakkesmod\data\acplugin\</li>      
      <li>Restart/Launch Rocket League with BakkesMod injected, open the AlphaConsole UI (F5) and the decals, flag, and banners should all show up. The Skyline decals require you to equip the Clean Cut decal either in your actual preset, or using BakkesMod's item mod (F2 -&gt; Items tab). TriHouse covers this a bit in his <a href="https://www.youtube.com/watch?v=5bQswLA-gV8">YouTube video</a>.</li>
    </ol>
  </div>
);

const TrainingPacks = () => {
  return (
    <div className="container">
      <h2>Training Packs</h2>
      <p>If you use Rocket League on PC (Epic or Steam), you can browse, download, and use these training packs in-game using my <a href="https://bakkesplugins.com/plugins/view/99">Team Training plugin</a> for BakkesMod which now supports browsing, organizing, and loading single player custom training packs in addition to multiplayer training packs.</p>
      <p>All the training packs displayed here are from the single player training packs that have been uploaded to my server via the Team Training plugin. There is no web UI for submitting training pack codes at this time.</p>
      <TrainingPacksList params={useLocation().search} />
    </div>
  );
}