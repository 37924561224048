import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import gcicon from '../assets/blue-gc-50x50.png'

const Styles = styled.div`
  .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;

    &:hover {
      color: white;
    }
  }

  .navbar-brand, .nav-link.active {
    color: rgb(3, 186, 252) !important;
  }

  .navbar-brand-span {
    padding-left: 10px;
  }

  .gcicon {
    width: 50px;
    height: 50px;
    margin-top: -2px;
    margin-left: 20px;
  }
`;

export const NavigationBar = props => {
  const { location } = props;
  return (
    <Styles>
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">
        <img src={gcicon} className="gcicon" alt="gc icon" />
          <span className="navbar-brand-span">Daft Penguin</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey={location.pathname}>
            <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/rocket-league">Rocket League</Nav.Link></Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  );
};